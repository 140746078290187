import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    SliderWidgetContainer as SourceSliderWidgetContainer,
} from 'SourceComponent/SliderWidget/SliderWidget.container';

/** @namespace Satisfly/Component/SliderWidget/Container */
export class SliderWidgetContainer extends SourceSliderWidgetContainer {
    containerProps() {
        const {
            device,
            onLoad,
            paramsJson,
        } = this.props;

        const { slider } = this.state;

        return {
            device,
            slider,
            paramsJson: paramsJson || '',
            onLoad,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderWidgetContainer);
