/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */

import { Field } from '@tilework/opus';

import { SliderQuery as SourceSliderQuery } from 'SourceQuery/Slider.query';

/** @namespace Satisfly/Query/Slider/Query */
export class SliderQuery extends SourceSliderQuery {
    _getSliderFields() {
        return [
            this._getSlidesField(),
            this._getSlideSpeedField(),
            new Field('slider_id'),
            new Field('title'),
            new Field('slides_to_display'),
            new Field('slides_to_scroll_mobile'),
            new Field('slides_to_display_tablet'),
            new Field('show_menu'),
            new Field('show_navigation'),
        ];
    }

    _getSlideFields() {
        return [
            ...super._getSlideFields(),
            'slide_link',
        ];
    }
}

export default new SliderQuery();
