/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import SlickSlider from 'react-slick';

import Html from 'Component/Html';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import Slider from 'Component/Slider';
import {
    SliderWidgetComponent as SourceSliderWidgetComponent,
} from 'SourceComponent/SliderWidget/SliderWidget.component';
import { ReactComponent as ArrowLeft } from 'Style/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'Style/icons/arrow-right.svg';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './SliderWidget.override.style';

/** @namespace Satisfly/Component/SliderWidget/Component */
export class SliderWidgetComponent extends SourceSliderWidgetComponent {
    settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: (
            <button>
                <div
                  block="SliderWidget"
                  elem="CustomArrow"
                >
                    <ArrowRight />
                </div>
            </button>
        ),
        prevArrow: (
            <button>
                <div
                  block="SliderWidget"
                  elem="CustomArrow"
                >
                  <ArrowLeft />
                </div>
            </button>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
        ],
    };

    getParamsJson() {
        const { paramsJson = '' } = this.props;
        // eslint-disable-next-line fp/no-let
        let result = {};

        if (!paramsJson) {
            return result;
        }

        try {
            result = JSON.parse(paramsJson);
        } catch (error) {
            result = null;
        }

        return result;
    }

    renderSlideImage(slide, i) {
        const { onLoad } = this.props;
        const {
            slide_link,
            slide_text,
            isPlaceholder,
            title: block,
        } = slide;

        const params = this.getParamsJson();

        const slideComponent = (
            <figure
              block="SliderWidget"
              elem="Figure"
              mods={ { isMultipleSlides: !!params?.multipleSlides } }
              key={ i }
            >
                <div>
                    <Image
                      mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                      ratio={ ImageRatio.IMG_CUSTOM }
                      src={ this.getSlideImage(slide) }
                      isPlaceholder={ isPlaceholder }
                      onImageLoad={ onLoad }
                    />
                    <figcaption
                      block="SliderWidget"
                      elem="Figcaption"
                      mix={ { block } }
                    >
                        <Html content={ slide_text || '' } />
                    </figcaption>
                </div>
            </figure>
        );

        if (slide_link) {
            return (

            <a href={ slide_link }>
                { slideComponent }
            </a>
            );
        }

        return slideComponent;
    }

    render() {
        const { activeImage } = this.state;
        const {
            slider: {
                slides,
                title: block,
                show_menu,
                show_navigation,
            },
            device: {
                isMobile,
            },
        } = this.props;

        const params = this.getParamsJson();

        if (!slides) {
            return null;
        }

        if (params?.multipleSlides) {
            return (
                <SlickSlider { ...this.settings }>
                    { slides.map(this.renderSlide.bind(this)) }
                </SlickSlider>
            );
        }

        return (
            <Slider
              mix={ { block: 'SliderWidget', mix: { block } } }
              showCrumbs={ show_menu }
              slides={ slides }
              activeImage={ activeImage }
              onActiveImageChange={ this.onActiveImageChange }
              showArrows={ !isMobile && slides.length > 1 && show_navigation }
            >
                { slides.map(this.renderSlide.bind(this)) }
            </Slider>
        );
    }
}

export default SliderWidgetComponent;
